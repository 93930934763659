import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";
import AddCheck from "@/components/add-check";
// import Editor from "@/components/editor";
import Menu from "@/components/dropdown";
import axios from "@/axios";
// import rawAxios from "axios";
import ProfileInfoCard from "@/components/profile-info-card";
import modalContent from "@/components/modal-content";
import Dropdown from "@/components/dropdown";
import ProfileInfo from "@/components/profile-info";
import tabs from "@/components/dashboard-components/tabs";
import Card from "@shared/sc-journey/card";
import cardShort from "@shared/sc-journey/card-short";
import nonReferenceCardShort from "@shared/sc-journey/non-reference-card-short";
import Icons from "@/components/icons-list";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Input from "@/components/input";
import Button from "@/components/button/index.vue";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import SendIcon from "@/assets/icons/profile-view/send.svg";
import SendLater from "@/assets/icons/profile-view/sendlater.svg";
import ArrowDown from "@/assets/icons/profile-view/arrow-blue.svg";
import DropDownArrow from "@/assets/icons/profile-view/arrow-white.svg";
import FromIcon from "@/assets/icons/profile-view/send-mail.svg";
import BoldIcon from "@/assets/icons/profile-view/bold-icon.svg";
import ItalicIcon from "@/assets/icons/profile-view/italic-icon.svg";
import UnderlineIcon from "@/assets/icons/profile-view/underline-icon.svg";
import StrikeThroughIcon from "@/assets/icons/profile-view/strikethrough-icon.svg";
import CollapseIcon from "@/assets/icons/profile-view/collapse.svg";
// import { uuid } from "vue-uuid";
const checklist = () => import("@/components/dashboard-components/checklist");
import CallLogModal from "@/components/sc-journey/modals/call-log.vue";
import TaskModal from "@/components/sc-journey/modals/task.vue";
import CompanyModal from "@/components/sc-journey/modals/company.vue";
import RefereeModal from "@/components/sc-journey/modals/referee.vue";
import { mapActions, mapGetters } from "vuex";
import FormBuilder from "@shared/components/form-builder";
import Empty from "@shared/empty";
import Timeline from "@shared/timeline";
import DocumentUpload from "@shared/document-upload";
import tenantFormUi from "@shared/referee-form";
import { format } from "date-fns";
import Loader from "@/components/loader";
import refreeSvg from "@/assets/svg/refree.svg";
import candidateSvg from "@/assets/svg/candidate.svg";

import pdfViewModal from "@shared/components/pdf-view/pdf-view-modal";
import MailModal from "@shared/sc-journey/modals/mail.vue";
import NoteModal from "@shared/sc-journey/modals/note.vue";
// todo remove when maksym's code is merged
import DvDropdown from "@/components/dropdown-base";
import HooyuCheckResultsViewer from "@shared/components/integrated-checks/hooyu.vue";
import TransunionCheckResultsViewer from "@shared/components/integrated-checks/transunion.vue";
import DBSCheckResultsViewer from "@shared/components/integrated-checks/dbs.vue";
import { getRefreeFormFields } from "@shared/utils/functions";
const EmailNote = () => import("./components/email-note");
const FilePreview = () => import("@shared/components/file-preview");
const DeclineNote = () => import("@/components/decline-note");
const WorkAllocation = () => import("./components/work-allocation");
// import ModalConfirm from "@shared/modal-confirm";
const ModalConfirm = () => import("@shared/modal-confirm");

export default {
    components: {
        // Editor,
        DvDropdown,
        Empty,
        Loader,
        FormBuilder,
        quillEditor,
        AddCheck,
        "menu-list": Menu,
        "profile-info": ProfileInfo,
        "profile-info-card": ProfileInfoCard,
        "icons-list": Icons,
        modalContent,
        "neo-input": Input,
        "neo-button": Button,
        "vue-multiselect": Multiselect,
        Card,
        cardShort,
        nonReferenceCardShort,
        UnderlineIcon,
        tabs,
        CollapseIcon,
        StrikeThroughIcon,
        Dropdown,
        DropDownArrow,
        ItalicIcon,
        BoldIcon,
        FromIcon,
        ArrowDown,
        SendIcon,
        SendLater,
        CloseIcon,
        Timeline,
        checklist,
        CallLogModal,
        TaskModal,
        CompanyModal,
        RefereeModal,
        tenantFormUi,
        DocumentUpload,
        refreeSvg,
        candidateSvg,
        HooyuCheckResultsViewer,
        TransunionCheckResultsViewer,
        DBSCheckResultsViewer,
        pdfViewModal,
        EmailNote,
        MailModal,
        NoteModal,
        FilePreview,
        DeclineNote,
        WorkAllocation,
        ModalConfirm,
    },
    title() {
        return `${this.$route.params.caseid}`;
    },
    data() {
        return {
            form_user_list: [],
            additional_form_key: 2,
            selected_form_user: null,
            selected_dbs_form: null,
            dbs_form_list: [],
            dropdown_loaders: {
                user: false,
                form: false,
            },
            isUploading: false,
            filesList: [],
            component_type: "",
            formPreviewData: null,
            attachedFiles: [],
            case_id: "",
            svg: null,
            attachments: {
                mail_attachments: 0,
                notes_attachments: 0,
            },
            content: "",
            //  = undefined;: undefined,
            dummystatus: "none",
            // uuid: uuid.v4(),
            sendOptions: false,
            value: null,
            emailAction: "",
            emailFormSettings: {
                reply: false,
            },
            emailFormValues: {
                subject: "",
                html_body: "",
                references: "",
                in_reply_to: "",
                delivery_date: "2022-11-09T05:55:53.465Z", // ?
                email_to: "",
                email_trail_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6", // ?
                external_id: "",
                tag: "candidate",
                cc: null,
                bcc: null,
                external_id_type: "candidate",
                placeholder_metadata: [],
            },
            roleOptions: false, // TODO move to the modal component
            isCollapsed: false,
            selectedReferee: null,
            sendToRole: "",
            activeCheck: "",
            tabs: {
                selected: 2,
                list: [
                    {
                        id: 1,
                        label: "Input",
                    },
                    {
                        id: 2,
                        label: "Checks",
                    },
                ],
            },
            roleValue: [],
            rolesOptions: ["one", "two", "three"],
            activeTab: "one",
            lists: [],
            notes: {
                type: "internal",
                note: "",
                attachments: null,
                external_id: null,
                external_id_type: null,
            },
            clientsList: [],
            statusList: [
                {
                    id: "1",
                    label: "Completed",
                    value: "completed",
                    styles: {
                        backgroundColor: "#3E9F3C",
                        color: "#fff",
                    },
                },
                {
                    id: "2",
                    label: "Action Needed",
                    value: "action_needed",
                    styles: {
                        backgroundColor: "#F2F55A",
                        color: "#282828",
                    },
                },
                {
                    id: "3",
                    label: "On Hold",
                    value: "on_hold",
                    styles: {
                        backgroundColor: "#DCA310",
                        color: "#fff",
                    },
                },
            ],
            tenantCheckStatusList: [],
            activeIcon: "",
            cadidateFormStatusTimeline: {
                header: {
                    label: "Subject Form Status",
                    icon: "#",
                },
                timeline: [],
            },
            checksTimeline: [
                {
                    header: {
                        label: "Gap Validation",
                        icon: "#",
                    },
                    timeline: [],
                },
                {
                    header: {
                        label: "Address History",
                        icon: "#",
                    },
                    timeline: [],
                },
            ],
            // todo fix this, use a better way to implement modals and email temps
            selectedConfig: {
                history: {
                    init: async function (id, type) {
                        this.methods.changeSettings(id, type);
                        this.props.isLoading = true;
                        const emails = await this.methods.fetchEmails(id, type);
                        this.props.emails = emails;
                        this.props.isLoading = false;
                        this.methods.scrollToSection();
                    },
                    methods: {
                        changeSettings: (id) => {
                            if (!id) this.activeCheck = "";
                            this.activeIcon = "history";
                            this.activeType = null;
                            this.showSubTypeCard = false;
                            this.showCandidateForm = false;
                        },
                        fetchEmails: async (payload, type) => {
                            const id = payload || this.$route?.query["caseid"];
                            let url = `/activity-history`;
                            try {
                                let form_data = {
                                    external_id_type: type ? type : payload ? "referee" : "candidate",
                                    external_id: id,
                                    activity_type: "all",
                                };
                                let { data } = await axios.post(url, form_data);
                                let emailsData = data.map((email) => {
                                    let obj = email;
                                    obj.type = email.activity_type;
                                    switch (email.activity_type) {
                                        case "email":
                                            obj.actions = ["reply", "reply-all", "forward", "check"];
                                            break;
                                        case "note":
                                            obj.actions = ["badge", "delete", "check"];
                                            if (email?.attachments?.length > 0) {
                                                obj.actions.splice(1, 0, "attachments");
                                            }
                                            break;
                                        case "call":
                                            obj.actions = ["alarm", "check"];
                                            break;
                                        case "tasks":
                                            obj.actions = ["delete", "edit", "check"];
                                            break;
                                        default:
                                            obj.actions = ["check"];
                                            break;
                                    }
                                    return obj;
                                });
                                this.fetchNotificationCount(this.case_id);
                                this.fetchCaseNotificationCount();
                                return { emails: emailsData, external_id_type: form_data.external_id_type, external_id: form_data.external_id, activity_type: form_data.activity_type };
                            } catch (error) {
                                console.log(error, "Error in fetchEmails");
                            }
                        },
                        scrollToSection: () => {
                            const hashvalue = this.$route.hash?.replace("#", "");
                            setTimeout(() => {
                                const sectionele = document.querySelectorAll(`[id="${hashvalue}"]`)[0];
                                sectionele?.scrollIntoView({ behaviour: "smooth" });
                            }, 100);
                        },
                    },
                    component: checklist,
                    events: {
                        activityAction: this.handleChecklistAction,
                    },
                    props: {
                        isLoading: false,
                        emails: [],
                    },
                },
                tel: {
                    init: () => {
                        this.$modal.show("call-log-modal");
                    },
                },
                task: {
                    init: () => {
                        this.$modal.show("profile-task-modal");
                    },
                },
                mail: {
                    init: () => {
                        this.resetEmailForm();
                    },
                },
                // overview: {
                //   action: () => {
                //     this.activeCheck = "";
                //   },
                //   component: overview,
                //   props: {

                //   }
                // }
            },
            activeCheckSubtype: null,
            pdfLink: null,
            isReportLoading: false,
            activeClient: "",
            isBrowseDirModalOpen: false, // TODO move to modal component
            emailTemplates: [],
            activeCallLog: null,
            currentRefereeForm: [],
            currentRefereeFormLoading: false,
            show_referee_form: false,
            fileUploadField: null,
            isTimelineLoading: false,
            activeType: null,
            nonRefCheckData: null,
            showSubTypeCard: false,
            candidateData: null,
            showCandidateForm: false,
            CaseCheckFormPreviewData: [],
            CaseCheckFormPreviewLoader: false,
            activeTaskData: null,
            emailActions: {
                task: false,
                date: null,
                time: null,
                task_assignee_id: null,
                notes: "",
                id: null,
                task_assignee_type: null,
                isClicked: false,
            },
            notesAction: {
                task: false,
                data: null,
                time: null,
                task_assignee_id: null,
                id: null,
                task_assignee: null,
                task_assignee_type: null,
            },
            assigneeOptions: [],
            loadingStates: {
                email: false,
                note: false,
                allocation: false,
            },
            allTeams: [],
            allUsers: [],
            isLoading: false,
            dbsStatus: null,
            move_to_selected_activity_history: null,
            gapErrors: {},
            modalConfirmData: {
                config: {
                    showHeader: true,
                },
            },
        };
    },

    //* /////////////////// MOUNTED //////////////////////////////////
    async mounted() {
        this.case_id = this.$route.query.caseid;
        await this.fetchRefChecks(this.$route.query.caseid);
        this.fetchFormPreview();
        this.isTimelineLoading = true;
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        await this.fetchTemplateList();
        await this.fetchAssignee();

        await this.fetchTimeline();
        const checkId = this.$route.query.check || null;
        const type = this.$route.query.type || null;
        if (checkId) {
            this.isClicked({ key: checkId, type });
        } else {
            this.activeCheck = "overview";
        }
        if (this.$route.query.action) {
            let active_action = this.$route.query.action;
            this.handleSelectIconList(active_action);
        }
        await this.fetchCaseNotificationCount(this.case_id);
        if (this.$route.hash) {
            this.move_to_selected_activity_history = this.$route.hash.replace("#", "");
        }
        this.isTimelineLoading = false;
    },

    watch: {
        content(val) {
            this.delta = val;
        },
    },

    methods: {
        ...mapActions(["fetchRefChecks", "fetchCaseNotificationCount", "fetchNotificationCount"]),
        // async fetchRefChecks() {
        // 	try {
        // 		const case_id = this.$route.query.caseid;
        // 		const { data } = await axios(`case/${case_id}/check`)
        // 		this.checkLists = data.data || []
        // 	} catch (error) {
        // 		console.log('error fetch checks-list :>> ', error);
        // 	}
        // },
        getCaseDetails(data) {
            this.candidateData = data;
        },

        fetchFileName(event, id) {
            // code for attached file names
            const { files } = event.target;

            // if (files.length > 0) {
            // 	for (let i = 0; i < files.length; i++) {
            // 		const element = files[i];
            // 		if (!this.attachedFiles.some((x) => x.name === element.name)) {
            // 			this.attachedFiles.push(element);
            // 		}
            // 	}
            // }

            if (id == "mail_attachments") {
                this.attachedFiles = files;
                this.attachments.mail_attachments = files.length;
            } else if (id == "notes_attachments") {
                this.notes.attachments = files;
                this.attachments.notes_attachments = files.length;
            }
        },
        handleClick(name, svg) {
            this.dummystatus = name;
            this.svg = svg;
        },
        async selectTab(id) {
            this.tabs.selected = id;
            if (!this.formPreviewData) await this.fetchFormPreview();
            if (!(this.form_user_list && this.form_user_list.length > 0)) await this.getAdditionalFormInput(this.case_id);
        },
        // selectCard(id) {
        //   this.selected = this.selected === id ? "" : id;
        // },
        collapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        handleRoles(role) {
            console.log(role, "ROLE");
            console.log("this.candidateData :>> ", this.candidateData);
            if (role === "client") this.emailFormValues.email_to = this.candidateData?.client_email;
            else if (role === "candidate") this.emailFormValues.email_to = this.candidateData?.email;
        },
        // triggers when sidebar check card is clicked
        async isClicked({ key, type, subtype, stage_id, title, id }) {
            this.selectedReferee = null;
            // this.showCandidateForm = false
            // this.showSubTypeCard = false
            // this.showSubTypeCard = false
            if (this.activeCheck === key) return;
            this.$router.push({ query: { caseid: this.case_id, check: key, type } });
            this.activeCheck = key;
            this.activeCheckSubtype = subtype;
            this.activeIcon = "";
            this.activeClient = "";
            if (type) {
                type = type.toLowerCase();
                this.activeType = type;
                if (type === "reference") {
                    this.component_type = "card";
                } else if (type === "candidate") {
                    // to do: change to something else according to api
                    this.component_type = "card-short";
                } else {
                    this.component_type = "non-reference-card-short";
                }
            }
            this.clientsList = [];
            const case_id = this.$route.query.caseid;
            if (type !== "static" && type !== "non reference" && type !== "integrated") {
                this.fetchRefereeStatuses();
                await this.fetchClientList(case_id, key);
            } else if (["non reference", "integrated"].includes(type)) {
                this.activeClient = id;
                this.nonRefCheckData = {
                    title,
                    stage_id,
                    id,
                    key,
                    checked: true,
                };
                await this.fetchTenantStatuses();
                this.showCandidateForm = true;
                await this.fetchCaseCheckFormPreview();
                this.showSubTypeCard = true;

                if (this.integratedCheckSubType === "transunion") {
                    this.$refs?.["transunion-check-viewer"]?.runCheck();
                }
            } else {
                this.fetchRefereeStatuses();
            }
        },
        async fetchClientList(case_id, key) {
            try {
                const { data } = await axios(`case/${case_id}/reference-check/${key}`);
                if (data && data.data)
                    this.clientsList = data.data.map((el) => ({
                        ...el,
                        id: el.id,
                        name: el.title || "-",
                        email: el.referee_email || "-",
                        status: el.status_name,
                        refree: {
                            name: el.referee_name || "-",
                            phone: el.referee_phone || "-",
                            email: el.referee_email || "-",
                        },
                        checked: false,
                    }));
            } catch (error) {
                console.log("fetchClientList error :>> ", error);
            }
        },
        handleCloseModal(modal_name) {
            this.$modal.hide(modal_name);
            // this.resetEmailForm();
        },
        async handleSelectIconList(modal_name) {
            console.log("modal_name :>> ", modal_name);
            this.handleResetCaseNotes();
            // remove if reset page settings anyway:
            if (this.activeIcon === modal_name) return;

            this.activeIcon = modal_name;
            if (this.selectedConfig[modal_name] && this.selectedConfig[modal_name].init) {
                if (modal_name == "history") {
                    this.selectedConfig[modal_name].props.emails = [];
                }
                await this.selectedConfig[modal_name].init();
            }
            // this.activeCheck = "";
            // this.selectedReferee = null;
        },
        resetTaskNotes() {
            this.notesAction = {
                task: false,
                data: null,
                time: null,
                task_assignee_id: null,
                id: null,
                task_assignee: null,
                task_assignee_type: null,
            };
        },
        async createCaseNotes(closeCallback) {
            let notes = this.notes;
            let payload = {
                user_id: this.getUser.id,
                note_text: notes.note,
                note_type: notes.type,
                external_id: notes.external_id || this.case_id,
                case_id: this.case_id,
                external_id_type: notes.external_id_type || "candidate", // If notes created from profile card panel then type = candidate else refrence
            };

            if (this.notesAction.task) {
                payload = { ...payload, assignee_type: this.notesAction?.task_assignee?.user_type, assignee_id: this.notesAction?.task_assignee?.id, target_time: this.formatDate(this.notesAction.date, this.notesAction.time).toISOString(), task_title: notes.note, task_flag: this.notesAction.task };
            }

            if (!this.notes.note || !this.notes.type) return this.$toast.error("Please Fill mandatory fields");
            let formData = new FormData();
            for (let key in payload) {
                formData.append(key, payload[key]);
            }
            // formData.append("user_id", payload.user_id);
            // formData.append("note_text", payload.note_text);
            // formData.append("note_type", payload.note_type);
            if (notes.attachments && notes.attachments.length > 0) {
                for (let i = 0; i < notes.attachments.length; i++) {
                    const element = notes.attachments[i];
                    formData.append("attachments", element);
                }
            }
            // formData.append("external_id", payload.external_id);
            // formData.append("external_id_type", payload.external_id_type);
            // formData.append("case_id", payload.case_id);
            try {
                this.loadingStates.note = true;
                let { data } = await axios.post("/case-notes", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                this.handleResetCaseNotes();
                // this.handleCloseModal('notes');
                closeCallback();
                this.resetActiveIcon();
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.case_id);
                this.$toast.success(data.message || "notes created");
                this.resetTaskNotes();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to create notes");
            }
            this.loadingStates.note = false;
        },
        resetEmailAttachments() {
            this.attachedFiles = [];
        },
        handleResetCaseNotes() {
            this.notes = {
                type: "",
                note: "",
                attachments: null,
            };
        },
        // reference card referee form data
        // file list
        // getFileFromFormData() {

        // },
        /***
         * this function is used for fetching both
         * files and form fields for individual referee cards
         * second param is for fetching files only altho they share the same API
         */
        async getRefereeFormData(ref_id, fetchFilesOnly = false) {
            if (!fetchFilesOnly) this.currentRefereeForm = [];
            this.currentRefereeFormLoading = true;
            this.isUploading = true;
            try {
                // const case_id = this.case_id
                const case_id = this.$route.query.caseid;
                const case_reference_id = ref_id;
                const { data } = await axios(`referee/referee/form/${case_id}/${case_reference_id}`);
                if (!fetchFilesOnly) this.currentRefereeForm = data.form_values.find((ele) => ele.field_type === "File" && !ele.can_approve);
                // const getCorrectToggle = (status) => {
                // 	// if (status) {
                this.fileUploadField = data.form_values.find((ele) => ele.field_type === "File" && !ele.can_approve);
                if (!fetchFilesOnly) this.currentRefereeForm = getRefreeFormFields(data);
                console.log("this.currentRefereeForm :>> ", this.currentRefereeForm);
                this.filesList = data.file_links.map((file) => ({
                    name: file.filename,
                    id: file.link,
                    link: file.link,
                }));
            } catch (error) {
                console.log("error :>> getRefereeFormData", error);
            }
            this.currentRefereeFormLoading = false;
            this.isUploading = false;
        },
        async UploadRefDocuments(files) {
            this.isUploading = true;
            const formData = new FormData();
            const case_reference_id = this.currentActiveClient.id;

            // formData.append('attachments', files)
            const params = {
                case_id: this.case_id,
                case_reference_id,
                field_id: this.fileUploadField.id,
                attachments: files,
            };
            files.forEach((file) => {
                formData.append("case_id", params.case_id);
                formData.append("case_reference_id", params.case_reference_id);
                formData.append("field_id", params.field_id);
                formData.append("attachments", file.file);
            });
            try {
                const { data } = await axios.post(`referee/referee/upload-docs`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (data) {
                    // todo remove the function getRefereeFormData to re-fetch form
                    // and add file download link from the api response
                    // for now its #
                    await this.getRefereeFormData(case_reference_id, true);
                    this.filesList = [...this.filesList];
                    this.$toast.success(data?.message || "File uploaded");
                }
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.isUploading = false;
        },

        resetEmailForm(values = {}, settings = {}) {
            // if (!this.emailFormSettings.reply) return; // ?
            this.resetEmailAttachments();
            this.sendOptions = false;
            this.emailFormSettings = {
                reply: false,
                cc: false,
                bcc: false,
                ...settings,
            };
            this.emailFormValues = {
                cc: "",
                bcc: "",
                subject: "",
                html_body: "",
                references: "",
                in_reply_to: "",
                delivery_date: "2022-11-09T05:55:53.465Z", // ?
                email_to: "",
                email_trail_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6", // ?
                external_id: "",
                tag: values.external_id_type || "candidate", // ?
                external_id_type: values.external_id_type || "candidate",
                placeholder_metadata: [],
                ...values,
            };
            this.emailActions = {
                task: false,
                date: null,
                time: null,
                task_assignee_id: null,
                notes: "",
                id: null,
                task_assignee_type: null,
                isClicked: false,
            };
            this.content = "";
            this.resetActiveIcon();
            // reset quill-editor content:
            this.content = this.emailFormValues.html_body;
        },
        async sendEmail(closeCallback) {
            let emailData = {};
            const { task, time, date, task_assignee_id, notes, task_assignee_type } = this.emailActions;
            this.emailActions.isClicked = true;
            if ((task && (!time || !date || !task_assignee_id || !notes || !task_assignee_type)) || !this.emailFormValues.email_to || !this.emailFormValues.subject || !this.removeHTML(this.content)) {
                this.$toast.error("Please fill all the mandatory fields.");
                return;
            }
            // eslint-disable-next-line no-useless-escape
            const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            if (!emailRegex.test(this.emailFormValues.email_to) || (this.emailFormSettings.cc && !emailRegex.test(this.emailFormValues.cc)) || (this.emailFormSettings.bcc && !emailRegex.test(this.emailFormValues.bcc))) return;

            let subject_tags = this.emailFormValues.subject.match(/<(.*?)>/g);
            let slash_tags = ["\t", "\n"];
            let subject = document.querySelector(".custom-quill .ql-editor")?.innerText;
            for (const index in subject_tags) {
                const text = subject_tags[index];
                subject = subject.replaceAll(text, "");
            }
            for (const index in slash_tags) {
                const text = slash_tags[index];
                subject = subject.replaceAll(text, "");
            }

            if (this.emailFormSettings.reply) {
                emailData = {
                    in_reply_to: this.emailFormValues.in_reply_to,
                    email_trail_id: this.emailFormValues.email_trail_id,
                    subject,
                    email_to: this.emailFormValues.email_to,
                    html_body: this.delta,
                    external_id: this.emailFormValues.external_id || this.$route.query["caseid"],
                    tag: this.emailFormValues.external_id_type,
                    external_id_type: this.emailFormValues.external_id_type,
                    cc: this.emailFormValues.cc,
                    bcc: this.emailFormValues.bcc,
                    placeholder_metadata: this.emailFormValues.placeholder_metadata,
                    task_flag: task,
                    target_time: this.formatDate(date, time).toISOString(),
                    case_id: this.case_id,
                    task_title: notes,
                    task_assignee_id: task_assignee_id,
                    task_assignee_type: task_assignee_type,
                };
            } else {
                emailData = {
                    subject,
                    html_body: this.delta,
                    email_to: this.emailFormValues.email_to,
                    external_id: this.emailFormValues.external_id || this.$route.query["caseid"],
                    tag: this.emailFormValues.external_id_type,
                    external_id_type: this.emailFormValues.external_id_type,
                    cc: this.emailFormValues.cc,
                    bcc: this.emailFormValues.bcc,
                    placeholder_metadata: this.emailFormValues.placeholder_metadata,
                    task_flag: task,
                    target_time: this.formatDate(date, time).toISOString(),
                    case_id: this.case_id,
                    task_title: notes,
                    task_assignee_id: task_assignee_id,
                    task_assignee_type: task_assignee_type,
                };
            }
            try {
                this.loadingStates.email = true;
                if (this.attachedFiles.length) {
                    const form_data = new FormData();
                    for (let key in emailData) {
                        form_data.append(key, emailData[key]);
                    }
                    if (this.attachedFiles.length > 0) {
                        for (let i = 0; i < this.attachedFiles.length; i++) {
                            const element = this.attachedFiles[i];
                            form_data.append("files", element);
                        }
                    }
                    // this.attachedFiles.forEach(file => {
                    //     form_data.append('files', file);
                    // });
                    await axios.post("email/send_with_attachment", form_data);
                } else {
                    await axios.post("email/send", emailData);
                }
                this.resetEmailForm();
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.case_id);
                // this.delta = undefined;
                this.$toast.success("Email sent successfully");
                // this.handleCloseModal("mail");
                closeCallback();

                if (this.selectedConfig[this.activeIcon]) this.selectedConfig[this.activeIcon].init();
            } catch (error) {
                let message;
                if (error?.response?.data?.detail && typeof error?.response?.data?.detail === "string") message = error?.response?.data?.detail;
                this.$toast.error(message || "Couldn't send email");
            }
            this.loadingStates.email = false;
        },
        async generateReport() {
            this.isReportLoading = true;
            // this.$modal.show('pdf-view-modal');
            try {
                let url = `report/generate/case?case_id=${this.$route.query.caseid}`;
                let { data } = await axios.get(url);
                // to do : remove this dummy link
                this.pdfLink = data.report_url;
                window.open(this.pdfLink);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Error While creating report");
            }
            this.isReportLoading = false;
        },
        async handleCardClicked(card_id) {
            this.showSubTypeCard = true;
            if (this.integratedCheckSubType !== "hooyu") {
                this.show_referee_form = true;
                if (this.activeClient === card_id) return;
                this.closeActiveComponent();
                this.activeClient = card_id;
                this.clientsList = this.clientsList.map((el) => ({ ...el, checked: el.id === card_id }));
                // this.filesList = []
                // this.fileUploadField = null
                await this.getRefereeFormData(card_id);
                /// call api and stuffs here
            }
            // // do something for non reference card //
            // else {

            // }
        },

        async fetchCaseCheckFormPreview() {
            this.activeIcon = "";
            const case_id = this.case_id;
            const tenant_check_id = this.nonRefCheckData.key;
            this.CaseCheckFormPreviewLoader = true;
            try {
                const { data } = await axios(`case/form/${case_id}?tenant_check_id=${tenant_check_id}`);
                this.CaseCheckFormPreviewData = data;
            } catch (error) {
                console.log(error);
            }
            this.CaseCheckFormPreviewLoader = false;

            // post(``,{
            // 	tenant_check_id: this.$store.getters.getTenantId
            // })
        },

        handleChange(options = {}) {
            const { id, key, value } = options;
            const object = this.currentRefereeForm.find((el) => el.id === id);
            object[key] = value;
            this.currentRefereeForm = this.currentRefereeForm.map((el) => (el.id === id ? object : el));
        },

        async deleteFile(filename) {
            let params = {
                case_id: this.case_id,
                case_reference_id: this.currentActiveClient.id,
                field_id: this.fileUploadField.id,
                file_name: filename,
            };
            try {
                const { data } = await axios.post(`referee/referee/delete-docs`, params);
                if (data) {
                    this.$toast.info("File deleted");
                    this.filesList = this.filesList.filter((ele) => ele.name !== filename);
                }
            } catch (error) {
                console.log("error :>>deleteFile ", error);
            }
        },

        handleChecklistAction(e) {
            if (e.type === "reply") {
                this.emailFormSettings.reply = true;
                const values = {
                    email_to: e.data.email_from,
                    subject: e.data.subject,
                    in_reply_to: e.data.message_id,
                    email_trail_id: e.data.email_trail_id,
                    external_id: e.data.external_id,
                    // tag: e.data.tag,
                    cc: e.data.cc,
                    bcc: e.data.bcc,
                };
                const settings = {
                    reply: true,
                };
                this.resetEmailForm(values, settings);
                this.$modal.show("mail");
            }
        },
        resetActiveIcon(value = "") {
            this.activeIcon = value;
        },
        async logActivity(payload) {
            let formatedDate = this.formatDate(payload.date, payload.time);
            // TODO
            const case_id = this.$route.query.caseid;
            let payload_data = {
                on: formatedDate,
                contacted_person: payload.contacted,
                phone_no: payload.phone,
                external_id: this.activeCallLog ? this.activeCallLog.id : case_id,
                content: payload.content,
            };
            const url = `/call_logs`;
            try {
                await axios.post(url, payload_data);
                this.$toast.success("call log saved");
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.case_id);
            } catch (error) {
                this.$toast.error("failed to create call log");
            }
        },
        formatDate(date, time) {
            let dateObj = new Date(date);
            let timeObj = new Date(time);
            let hours = timeObj.getHours();
            let minutes = timeObj.getMinutes();
            let seconds = timeObj.getSeconds();
            return new Date(dateObj.setHours(hours, minutes, seconds));
        },
        async saveTask(payload) {
            let formatedDate = this.formatDate(payload.dueDate, payload.time);
            const apiPayload = {
                external_id: this.activeTaskData?.id || this.case_id,
                user_id: this.getUser.id,
                task_text: payload.note,
                target_time: formatedDate,
                case_id: this.case_id,
                // visibility: "internal", //TODO: Create UI for internal and customer in task modal
                external_id_type: this.activeTaskData?.type || "candidate",
            };

            try {
                const url = "/case-tasks";
                await axios.post(url, apiPayload);
                this.$toast.success("Task Saved");
                this.fetchNotificationCount();
                this.fetchCaseNotificationCount(this.case_id);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to create task");
            }
        },
        async fetchFormPreview() {
            // Remove hardcoded screening package id(for debugging purposes only)
            const case_id = this.$route.query.caseid;
            const url = `/case/form/${case_id}`;
            try {
                const data = await axios.get(url);
                this.formPreviewData = data.data;
            } catch (error) {
                console.error("[+] error in fetching form data", error);
            }
        },
        formatSections(data) {
            return { sections: [data.data] };
        },
        async saveSection(payload) {
            console.log("payload :>> ", payload);
            let sectionSaved = false;

            const case_id = this.$route.query.caseid;
            let url = `/case/form/${case_id}`;
            // if (this.selected_dbs_form) url = `case/${case_id}/additonal-forms/${this.selected_dbs_form.id}`;
            if (this.selected_dbs_form) url = `case/${case_id}/additional-forms/${this.selected_dbs_form.id}`;

            try {
                await axios.post(url, this.formatSections(payload));
                sectionSaved = true;
            } catch (error) {
                const { error: resp } = error?.response?.data?.detail || {};
                if (resp) {
                    if (resp.code === "ERROR.GAP_VALIDATION") {
                        this.gapErrors = {
                            message: resp.message,
                            gaps: resp.gaps,
                            gapErrorsView: true,
                        };
                    }
                }
                sectionSaved = false;
                throw error;
            }
            return sectionSaved;
        },
        async submitCaseForm(caseId) {
            const url = `/case/form/${caseId}/submit`;
            try {
                const response = await axios.post(url);
                this.$toast.success(response.data.message || "Form Saved!");
            } catch (error) {
                this.$toast.error(error.response.data.detail);
                return false;
            }
        },
        async saveForm() {
            try {
                // const sectionSaved = await this.saveSection(payload); // moved inside the formbuilder to handle response
                // if (sectionSaved) {
                    await this.submitCaseForm(this.case_id);
                    this.showFormSubmissionNotification = true;
                    this.gapErrors = {
                        gapErrorsView: false,
                    };
                // }
            } catch (error) {
                this.formSubmissionStatusMessage = "Could not process form at the moment.please contact administrator.";
                // this.showFormSubmissionNotification=true
            }
            // this.showFormSubmissionNotification=true
        },
        async fetchTimeline() {
            this.isTimelineLoading = true;
            const case_id = this.$route.query.caseid;
            let status_timeline = {
                header: {
                    label: "Candidate Form Status",
                    icon: "#",
                },
                timeline: [],
            };
            let non_timeline = {
                header: {
                    label: "Checks",
                    icon: "#",
                },
                timeline: [],
                showDates: false,
            };

            // todo instead of calling api's sequentally
            // make a Promise array and fetch them all at once
            try {
                const { data } = await axios(`case/${case_id}/events-timeline`);
                if (data)
                    status_timeline.timeline = data.data.map((el) => ({
                        date: new Date(el.timestamp),
                        title: el.event_name,
                        description: "",
                        color: el.status_color || "#3b2cd4",
                    }));
            } catch (error) {
                console.log("error1 :>> ", error);
            }
            try {
                const { data } = await axios(`case/${case_id}/checks-timeline`);
                this.checksTimeline = data.data.map((obj) => {
                    let data = {};
                    data = {
                        header: {
                            label: obj.check_name,
                            icon: "#",
                        },
                    };
                    data.timeline = obj.timeline.map((el) => ({
                        date: new Date(el.from_date),
                        title: el.title,
                        description: el.status_name,
                        color: el.status_color,
                    }));
                    return data;
                });
            } catch (error) {
                console.log("error2 :>> ", error);
            }
            try {
                const { data } = await axios(`case/${case_id}/non-timeline-checks`);
                if (data) {
                    non_timeline.timeline = data.data.map((el) => ({
                        // adding a dummy for key and contents
                        date: new Date(),
                        title: el.check_name || "-",
                        description: el.status_name,
                        color: el.status_color || "#3b2cd4",
                    }));
                }
            } catch (error) {
                console.log("error3 :>> ", error);
            }
            this.isTimelineLoading = false;
            this.checksTimeline.push(...[status_timeline, non_timeline]);
        },
        closeActiveComponent() {
            this.selectedReferee = null;
            this.activeCallLog = null;
            (this.activeTaskData = null), (this.activeIcon = "");
            this.isCollapsed = false;
            this.showCandidateForm = false;
        },
        async fetchRefereeStatuses() {
            try {
                const { data } = await axios(`referee/reference/status`);
                this.statusList = data?.statuses.map((status) => ({
                    ...status,
                    label: status.name,
                    value: status.internal_name,
                    styles: { backgroundColor: status.color || "#0000FF", color: status.text_color || "#fff" },
                }));
            } catch (error) {
                console.log("error fetchRefereeStatuses :>> ", error);
            }
        },
        // TODO can be merged with above function fetchRefereeStatuses()
        async fetchTenantStatuses() {
            try {
                const { data } = await axios.post(`tenant-check/checks-stages`, { tenant_check_id: this.nonRefCheckData.key });
                this.tenantCheckStatusList = data?.map((status) => ({
                    ...status,
                    label: status.name,
                    value: status.name,
                    styles: { backgroundColor: status.color || "#0000FF", color: status.text_color || "#fff" },
                }));
                this.nonRefCheckData = {
                    ...this.nonRefCheckData,
                    status: this.tenantCheckStatusList?.find((item) => item.stage_id === this.nonRefCheckData.stage_id),
                };
            } catch (error) {
                console.log("error fetchRefereeStatuses :>> ", error);
            }
        },
        handleStatusChangedTest() {
            // console.log('ele :>> test');
        },
        async handleStatusChanged(val, ref_id) {
            const case_id = this.$route.query.caseid;
            const case_reference_id = ref_id;
            if (this.activeType === "non reference" || this.activeType === "integrated") {
                try {
                    const response = await axios.post(`case/case-checks-status`, {
                        case_id,
                        tenant_check_id: this.nonRefCheckData.key,
                        active_stage: val.id,
                    });
                    if (response) {
                        this.$toast.success(`Status changed successfully!`);
                    }
                    await this.fetchRefChecks(case_id);
                } catch (error) {
                    console.log("error handleStatusChanged :>> ", error);
                }
            } else {
                try {
                    const response = await axios.put(`referee/reference/case`, {
                        case_id,
                        case_reference_id,
                        status_id: val.id,
                    });
                    if (response) {
                        this.$toast.success(`Status changed successfully!`);
                    }
                    // to do remove this and update client object manually
                    await this.fetchRefChecks(case_id);
                    await this.fetchClientList(case_id, this.activeCheck);
                } catch (error) {
                    console.log("error handleStatusChanged :>> ", error);
                }
            }
        },

        // reference check form
        async handleForm() {
            const case_id = this.case_id;
            const case_ref_id = this.currentActiveClient.id;
            const correctValue = (ref_status) => {
                // if (ref_status === 'unselected') return null
                // else
                return ref_status === "correct";
            };
            const getValue = (val) => {
                if (Array.isArray(val)) return val.join();
                else return val;
            };
            let formData = this.currentRefereeForm.map((el) => ({
                ...el,
                field_id: el.id,
                field_name: el.label,
                value: getValue(el.value),
                is_correct: correctValue(el.correct) || false,
                correct_value: Array.isArray(el.correct_value) ? JSON.stringify(el.correct_value) : el.correct_value || "",
            }));
            let param_data = {
                case_id: case_id,
                field_list: formData.map((el) => ({
                    id: el.id,
                    case_id: case_id,
                    section_field_id: el.api_data.section_field_id,
                    value_str: el.value,
                    value_int: el.api_data.value_int,
                    block_fe_id: el.api_data.block_fe_id,
                    field_fe_id: el.api_data.field_fe_id,
                    serial: el.api_data.serial,
                    field_serial: el.api_data.field_serial,
                    case_reference_id: case_ref_id,
                    Label: el.api_data.Label,
                    section_field_label: el.api_data.section_field_id,
                    label: el.label,
                    name: el.api_data.name,
                    field_type: el.api_data.field_type,
                    ref_action: el.ref_action || false,
                    field_type_id: el.api_data.field_type_id,
                    ref_input_value: el.correct_value,
                    ref_input_status: el.is_correct,
                    can_approve: el.api_data.can_approve,
                })),
            };
            try {
                //to do: change accoring to tenant app API for submitting form data
                const response = await axios.post(`referee/referee/form`, param_data);
                if (response) {
                    this.$toast.success(`Saved successfully!`);
                }
            } catch (error) {
                console.log("error :>> ", error);
            }
        },
        cancelForm() {
            // this.currentActiveClient = null
            this.activeClient = "";
            this.filesList = [];
            this.fileUploadField = null;
            this.clientsList = this.clientsList.map((el) => ({ ...el, selected: false }));
        },
        async getAdditionalFormInput(case_id) {
            try {
                const { data } = await axios(`case/${case_id}/additional-forms`);
                this.form_user_list = data.data || [];
            } catch (error) {
                console.log("data :>> ", error);
            }
        },
        handleChecked(ref_id) {
            console.log(ref_id);
        },
        async fetchTemplateList() {
            try {
                const url = `/email-template/list?external_id=${this.$store.getters.getTenantId}`;
                const result = await axios.get(url);
                const data = result.data.content.map((x) => ({
                    ...x,
                    created_at: x?.created_at ? format(new Date(x?.created_at), "MMM dd yyyy") : "",
                }));
                this.emailTemplates = data;
            } catch (error) {
                console.error(error.message);
            }
        },

        async fetchTemplateData(template) {
            try {
                const res = await axios.get(`email-template/${template.id}`);
                this.isTemplateModalOpen = true;
                const templateData = {
                    id: res.data.id,
                    content: res.data.body,
                    templateName: res.data.name,
                    templateSubject: res.data.subject,
                    system_default: template.system_default,
                    placeholder_metadata: res.data.placeholder_metadata,
                };
                return templateData;
            } catch (error) {
                console.log(error.message);
            }
        },
        async clickedEmailTemplate(template) {
            const templateData = await this.fetchTemplateData(template);
            this.content = templateData.content;
            this.emailFormValues.subject = templateData.templateSubject;
            this.emailFormValues.placeholder_metadata = templateData.placeholder_metadata;
        },
        handleClearCallActiveLog() {
            this.activeCallLog = null;
        },
        // async fetchUserFormList() {
        //     try {
        //         const { data } = await axios.get(``);
        //     } catch (error) {
        //         console.log("error :>> fetchUserFormList", error);
        //     }
        //     this.form_user_list = [];
        // },
        handleUserFormList(value) {
            this.selected_form_user = value;
            this.selected_dbs_form = null;
        },
        async fetchNewUserForm(value) {
            this.selected_dbs_form = value;
            await this.fetchAdditionalForm(this.case_id, this.selected_dbs_form.id);
        },
        async fetchAdditionalForm(case_id, case_additional_form_id) {
            try {
                // this.formPreviewData = {}
                const { data } = await axios(`case/${case_id}/additional-forms/${case_additional_form_id}`);
                this.formPreviewData = data;
                this.additional_form_key++;
                this.$forceUpdate();
            } catch (error) {
                console.log("error :>> ", error);
            }
        },
        async clearDbsFormInput() {
            this.selected_dbs_form = null;
            this.selected_form_user = null;
            // this.form_user_list = []
            await this.fetchFormPreview();
            this.additional_form_key++;
        },

        onPrintForm() {
            window.print();
        },
        openEmailNotes() {
            if (this.emailActions.task) this.$refs["email-note-modal"].showModal(this.emailActions);
        },
        async onNoteEmailSave(event) {
            this.emailActions = event;
            if (event.isEdit) {
                this.$set(this.selectedConfig[this.activeIcon].props, "isLoading", true);
                // let emails = this.selectedConfig[this.activeIcon].props.emails.emails;
                // let index = emails.findIndex((item) => item.id == event.id);
                // if (index && index != -1) {
                //     emails[index].task_text = event.notes;
                //     emails[index].assignee_id = event.task_assignee_id;
                //     emails[index].assignee_type = event.task_assignee_type;
                //     this.$set(this.selectedConfig[this.activeIcon].props.emails, "emails", emails);
                // }
                await this.onUpdateTask(event);
                await this.selectedConfig[this.activeIcon].init();
                this.$set(this.selectedConfig[this.activeIcon].props, "isLoading", false);
            }
        },
        async fetchAssignee() {
            try {
                const { data } = await axios.get(`/case/${this.case_id}/users`);
                this.assigneeOptions = data?.data ?? [];
                this.assigneeOptions = this.assigneeOptions.map((opt) => ({ ...opt, user_name: opt?.user_name ?? opt.email }));
            } catch (error) {
                console.log("[+] error while fetching assignee", error);
            }
        },
        onAssigneChange(/* value, type */) {
            // if(type==='email') { // TODO remove
            // 	this.emailActions.task_assignee_id = value.id;
            // 	this.emailActions.task_assignee_type = value.user_type;
            // }
        },
        async fetchAllocation(type) {
            const url = `/work-allocation/case/${this.case_id}/${type}`;
            try {
                const { data } = await axios.get(url);
                if (type === "groups") {
                    this.allTeams = data ?? [];
                } else {
                    this.allUsers = data ?? [];
                }
            } catch (error) {
                console.log(error, "[+] error while fetch allocation list");
            }
        },
        async onWorkAllocation() {
            this.loadingStates.allocation = true;
            await this.fetchAllocation("groups");
            await this.fetchAllocation("users");
            this.loadingStates.allocation = false;
            this.$modal.show("profile-work-allocation");
        },
        async onWorkAllocationSave({ type, status }) {
            //type: team or user
            this.loadingStates.allocation = true;
            let url = "";
            let payload = {
                case_ids: [{ id: this.case_id }],
            };
            if (type === "team") {
                url = "/work-allocation/case/tenant-group";
                payload.add_groups = status.allotted ? [status.id] : [];
                payload.remove_groups = status.allotted ? [] : [status.id];
            } else {
                url = "/work-allocation/case/tenant-user";
                payload.add_users = status.allotted ? [status.id] : [];
                payload.remove_users = status.allotted ? [] : [status.id];
            }
            try {
                await axios.post(url, payload);
                this.$toast.success("Saved Successfully!");
            } catch (error) {
                this.$toast.error("Saving Failed!");
            }
            this.loadingStates.allocation = false;
        },
        async runDBS() {
            let promise = await this.$refs.modalConfirm.show({
                message: "Is the ID Check clear or has the client witnessed the ID document?",
                buttonText: "Yes run DBS",
                title: "Run DBS",
            });
            if (promise) {
                if (this.case_id) {
                    this.isLoading = true;
                    try {
                        let url = `/integrations/dbs/${this.case_id}/run`;
                        let { data } = await axios.get(url);
                        // to do : remove this dummy link
                        this.dbsStatus = data;
                    } catch (error) {
                        this.$toast.error(error.response.data.detail || "Something went wrong. Please try again!");
                    }
                    this.isLoading = false;
                }
            }
        },
        async removeBlock(payload) {
            const url = `/case/form/${this.case_id}/multi-block/${payload}`;
            await axios.delete(url);
        },
        async removeField(payload) {
            const url = `/case/form/${this.case_id}/multi-field/${payload}`;
            await axios.delete(url);
        },

        onTaskEdit(event) {
            const { assignee_id, task_text, target_time, assignee_type, id } = event;
            this.emailActions = {
                task: true,
                date: new Date(target_time),
                time: new Date(target_time),
                task_assignee_id: assignee_id,
                notes: this.removeHTML(task_text),
                id,
                task_assignee_type: assignee_type,
                isClicked: false,
                isEdit: true,
            };
            // onBeforeOpen()
            this.$refs["email-note-modal"].showModal(this.emailActions);
        },
        async onTaskDelete(event) {
            console.log("event :>> ", event);
            let promise = await this.$refs.modalConfirm.show({
                message: "Are you sure you want to delete this task?",
                buttonText: "Delete",
                title: "Task",
            });
            if (promise) {
                this.$set(this.selectedConfig[this.activeIcon].props, "isLoading", true);
                // console.log("this.selectedConfig[this.activeIcon].props :>> ", this.selectedConfig[this.activeIcon].props.emails.emails);
                // let emails = this.selectedConfig[this.activeIcon].props.emails.emails.filter((item) => item.id !== event.id);
                // this.$set(this.selectedConfig[this.activeIcon].props.emails, "emails", emails);
                await this.deleteTask(event);
                await this.selectedConfig[this.activeIcon].init();
                this.$set(this.selectedConfig[this.activeIcon].props, "isLoading", false);
            }
        },
        removeHTML(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        async onUpdateTask({ id, notes, time, date, task_assignee_type, task_assignee_id }) {
            try {
                // this.formatDate(date, time).toISOString()
                let { data } = await axios.put("/case-tasks", {
                    id,
                    task_text: notes,
                    target_time: this.formatDate(date, time).toISOString(),
                    assignee_type: task_assignee_type,
                    assignee_id: task_assignee_id,
                });
                this.$toast.success(data.message || "Task updated");
            } catch (error) {
                console.log(error, "[+] failed to error while updating task");
            }
        },
        async deleteTask({ id }) {
            try {
                let { data } = await axios.delete(`/case-tasks/${id}`);
                this.$toast.success(data.message || "Tast Deleted");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to delete task");
                console.log(error, "[+] failed to delete task");
            }
        },
    },
    computed: {
        ...mapGetters(["getRefChecks", "getUser", "getCaseNotificationCount"]),

        integratedCheckSubType() {
            let integratedCheckMappings = {
                "49c99c03-d07b-4561-9a4c-a887e23bfa28": "hooyu",
                "ef395d91-a982-45a2-a308-b123b23771a5": "transunion",
                "7c6e45d8-e5ff-4f89-b950-fc9448d09878": "dbs",
            };
            return integratedCheckMappings[this.activeCheckSubtype];
        },

        referenceChecks() {
            return this.getRefChecks;
        },
        currentActiveClient() {
            if (this.activeClient && this.clientsList.length) return this.clientsList.find((client) => client.id === this.activeClient);
            else return null;
        },
        btns() {
            return [
                {
                    svg: "close-icon",
                    key: "in-progress",
                    name: "In Progress",
                },

                {
                    svg: "del-icon",
                    key: "on-hold",
                    name: "On Hold",
                },
            ];
        },
        cardActions() {
            return {
                resetReferee() {
                    this.selectedReferee = null;
                },
                selectCompany: (cardData) => {
                    this.selectedReferee = cardData;
                    this.$modal.show("referee-company-modal");
                },
                selectReferee: (cardData) => {
                    this.selectedReferee = cardData;
                    this.$modal.show("referee-modal");
                },
                newEmail: (cardData, type) => {
                    const values = {
                        external_id: cardData.id,
                        external_id_type: type,
                        tag: type,
                        email_to: cardData && cardData.refree ? cardData.refree.email : "", // TODO finish when API is ready
                    };
                    this.resetEmailForm(values);
                    this.activeIcon = "mail";
                    this.$modal.show("mail");
                },
                showActivities: async (cardData, type) => {
                    await this.selectedConfig.history.init(cardData.id, type);
                    this.isCollapsed = true;
                    this.selectedReferee = cardData;
                    this.show_referee_form = false;
                    this.showCandidateForm = false;
                    this.showSubTypeCard = false;
                    this.external_id_type = type;
                },
                newNote: (cardData, type) => {
                    // TODO use params
                    this.handleResetCaseNotes();

                    this.notes.external_id = cardData.id;
                    this.notes.external_id_type = type || "referee";
                    this.activeIcon = "notes";
                    this.$modal.show("notes");
                },
                logCall: (cardData, type) => {
                    // TODO use params
                    this.activeIcon = "tel";
                    this.activeCallLog = { ...cardData, type };
                    this.selectedConfig.tel.init(cardData.id);
                },
                task: (cardData, type) => {
                    // TODO use params
                    this.activeTaskData = { ...cardData, type };
                    this.activeIcon = "task";
                    this.selectedConfig.task.init();
                },
            };
        },
        computedUserList() {
            let list = [];
            let form_user_list = this.form_user_list;
            form_user_list.filter((item) => {
                let i = list.findIndex((x) => x.user_name == item.user_name);
                if (i <= -1) {
                    list.push(item);
                }
                return null;
            });
            return list;
        },
        computedFormList() {
            let list = this.form_user_list;
            if (this.selected_form_user) {
                list.filter((el) => el.user_name === this.selected_form_user.user_name);
            } else return [];
            return list;
        },
        hasEmailErrors() {
            const { task, time, date, task_assignee_id, notes, isClicked } = this.emailActions;
            return {
                date: isClicked && task && !date,
                time: isClicked && task && !time,
                task_assignee_id: isClicked && task && !task_assignee_id,
                notes: isClicked && task && !notes,
            };
        },
        refereeFormSaveDisabled() {
            return !this.currentRefereeForm.some((el) => el.ref_action);
        },
        hideScrollbar() {
            return this.selectedConfig[this.activeIcon]?.props?.isLoading;
        },
    },
};
